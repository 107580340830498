<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <div>
                  <div style="display: flex; justify-content: space-between">
                    <h6 class="mb-0">THỐNG KÊ ĐUA TOP</h6>

                  </div>
                  <b-form @submit.prevent="search()" >
                    <div class="filter-transaction">
                      <div class="row mb-12">

                        <b-form-group
                            id="input-group-4"
                            label="Nhập số điện thoại:"
                            label-for="dp1"
                            class="col-lg-2"
                        >
                          <input
                              type="text"
                              placeholder="Nhập số điện thoại"
                              class="form-control datatable-input"
                              v-model="paramFilter.phone"
                          />
                        </b-form-group>

                        <div class="col-md-6 mb-lg-0 mb-6">
                          <label>.</label><br />
                          <button
                              class="btn btn-primary btn-primary--icon mr-3"
                          >
                          <span>
                            <i class="la la-search"></i>
                              <span>Tìm kiếm</span>
                            </span>
                          </button>
                            <b-button v-if="items" variant="primary" @click="exportData"><i class="fa fa-download"></i>Xuất Excel</b-button>
                        </div>
                      </div>
                    </div>
                  </b-form>
                </div>
              </template>

              <b-table
                  striped
                  table-class="datatable-table"
                  hover
                  tbody-tr-class="datatable-row"
                  thead-tr-class="datatable-row"
                  thead-class="datatable-head"
                  details-td-class="datatable-cell"
                  :items="items"
                  :fields="fields"
              >
                <template #thead-top v-if="analytics">
                  <b-tr>
                    <b-th></b-th>
                    <b-th><span>PU:</span> <strong>{{ analytics.pu }}</strong></b-th>
                    <b-th></b-th>
                    <b-th></b-th>
                    <b-th><span>GMV:</span> <strong>{{numberFormat(parseInt(analytics.gmv))}}</strong></b-th>
                    <b-th></b-th>
                  </b-tr>
                </template>

                <template #cell(id)="data">
                  <div>
                    {{ data.index + 1 }}
                  </div>
                </template>
                <template #cell(name)="data">
                  <div >
                    <div>
                      {{data.item.name}}
                    </div>
                  </div>

                </template>
                <template #cell(position)="data">
                  <div style=" font-weight: bold">
                    {{data.item.position}}
                  </div>

                </template>

                <template #cell(total_amount)="data">
                  <div >
                    <div>
                      {{numberFormat(data.item.total_amount)}}
                    </div>
                  </div>
                </template>
                <template #cell(last_gmv)="data">
                  <div >
                    <div>
                      {{numberFormat(data.item.last_gmv)}}
                    </div>
                  </div>
                </template>
                <template #cell(compare)="data">
                  <div >
                    <div v-html="compareGmv(data.item.total_amount, data.item.last_gmv)">
                    </div>
                  </div>
                </template>
                  <template #cell(total_trans)="data">
                      <div >
                          {{numberFormat(data.item.total_trans)}}
                      </div>
                  </template>
                  <template #cell(last_log)="data">
                      <div v-if="data.item.log">
                          {{formatDateTime(data.item.last_log)}}
                      </div>
                  </template>
                  <template #cell(total_log)="data">
                      <div >
                          {{numberFormat(data.item.total_log)}}
                      </div>
                  </template>
                  <template #cell(frequency_log)="data">
                      <div v-if="data.item.days_log > 0">
                          {{Math.round((data.item.total_log/data.item.days_log) * 100) / 100}}
                      </div>
                      <div v-if="data.item.days_log < 1">
                          0
                      </div>
                  </template>
              </b-table>
              <div class="mt-3">
                <b-pagination
                    v-model="currentPage"
                    :link-gen="createLinkPage"
                    :number-of-pages="paginate.totalPage"
                    :total-rows="paginate.total"
                    :per-page="paginate.limit"
                    use-router
                    first-number
                    last-number
                    align="right"
                ></b-pagination>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import moment from "moment";
import CampaignRepository from "@/core/repositories/CampaignRepository";

const EventRepository = RepositoryFactory.get("event");

export default {
  name: "List.vue",
  mixins: [Common],
  components:{
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "STT",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "name",
          label: "Tên ",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      {
          key: "nick_name",
          label: "Nickname",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
      },
        {
          key: "phone",
          label: "SĐT ",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "position",
          label: "Vị trí trên BXH",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "total_amount",
          label: "GMV",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "last_time_completed",
          label: "Thời gian TT cuối",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "last_gmv", label: "GMV kỳ trước"
        },
        {
          key: "compare", label: "So sánh"
        },
          {
              key: "total_trans", label: "Transactions",
              tdClass: "datatable-cell",
              thClass: "datatable-cell",
          },
          {
              key: "last_log", label: "Truy cập lần cuối",
              tdClass: "datatable-cell",
              thClass: "datatable-cell",
          },
          {
              key: "total_log", label: "Lượt truy cập",
              tdClass: "datatable-cell",
              thClass: "datatable-cell",
          },
          {
              key: "frequency_log", label: "Tần suất truy cập",
              tdClass: "datatable-cell",
              thClass: "datatable-cell",
          }
      ],
      analytics: null,
      items: [],
      currentPage: 1,
      paginate: {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      clients: [],
      service_code: [],
      paramFilter:{
        phone:'',
      },
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Chiến dịch đua top", route: "/top-service" },
    ]);
    this.filter = this.$route.query;
  },
  methods: {
    convertString(string){
      return JSON.parse(string);
    },
    getLabelProcess(item,isClass = false,isStatus=false){
      let txtClass = 'label-light-info';
      let txt = 'Chưa diễn ra';
      let date_end = Date.parse(item.date_end)
      let date_start = Date.parse(item.date_start)
      let now =   Date.now()
      let status =0;
      if ( now >= date_start && now < date_end ){
        txtClass =  'label-light-warning'
        txt =  'Đang diễn ra'
        status =1;
      }
      if ( now >date_end){
        txtClass =  'label-light-success'
        txt =  'Đã kết thúc'
        status =2;
      }

      if (isClass){
        return txtClass;
      }
      if (isStatus){
        return status;
      }else {
        return txt;
      }
    },
    numberFormat(value) {

      if (typeof value != "number" ) {
        return 0;
      }
      var number = parseInt(value);
      if (isNaN(number) || number == null) {
        return 0;
      }
      var formatter = new Intl.NumberFormat("it-IT");
      return formatter.format(number);
    },
    getType(items){
      let array_type=[];
      let that = this;
      let label = '';
      items.forEach((item) => {
        if (!array_type.includes(item.type)){
          array_type.push(item.type)
        }
      })
      array_type.forEach((item)=>{
        that.arrayFilter.forEach((type)=>{
          if (item == type.status){
            label += type.label+ ', ';
          }
        })
      })
      return label.replace(/, +$/, "");
    },
    formatDate(value){
      return moment(String(value)).format("YYYY-MM-DD hh:mm:ss");
    },
    async search () {
      this.currentPage = 1;
      this.paginate = {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      }
      this.getList();
    },
    async getList(hide = false) {
      try {
        if (!hide) {
          this.$bus.$emit("show-loading", true);
        }

        this.paramFilter.page = this.currentPage;

        let response = await EventRepository.analysis(this.$route.params.id,this.paramFilter);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          let list = body.data.data;
          let items = list;

          this.analytics = items.analytics;
          this.items = items.data.data;
          this.paginate.total = items.data.total;
          this.paginate.totalPage = items.data.last_page;
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async getCodes() {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CampaignRepository.getOptions();
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          let that = this;
          body.data.payment_service.forEach((item) => {
            that.service_code.push(item);
          });
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    exportData() {
        this.$bus.$emit("show-loading", true);
        EventRepository.exportBxh(this.$route.params.id,this.paramFilter)
          .then((response) => {
              if (response.data.error_code) {
                  this.$bus.$emit("show-loading", false);
                  this.notifyAlert("warn", response.data.message);
                  return;
              }

              this.$bus.$emit("show-loading", false);
              this.notifyAlert("success", "Lấy dữ liệu thành công");
              return;
          })
          .catch(() => {
              this.$bus.$emit("show-loading", false);
          });
      },
    async destroy(id) {
      if (confirm("Bạn có chắc xóa cài đặt?")) {
        try {
          this.$bus.$emit("show-loading", true);
          let response = await EventRepository.delete(id);
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: response.data.message,
            });
            this.getList(true);
          }
        } catch (e) {
          this.$bus.$emit("show-loading", false);
        }
      }
    },
    compareGmv(gmv, lastGmv) {
      let currentGmv = parseInt(gmv);
      let lastMonthGmv = parseInt(lastGmv);
      let result = this.calculatorGmv(currentGmv, lastMonthGmv);

      if (result >= 0) {
        return "<strong class='text-success'><i class='fas fa-sort-up text-success'></i> " + this.numberFormat(result) + "%</strong>";
      }

      return "<strong class='text-danger'><i class='fas fa-sort-down text-danger'></i> " + this.numberFormat(result) + "%</strong>";
    },
    calculatorGmv(currentGmv, lastMonthGmv) {
      if (currentGmv === lastMonthGmv) {
        return 0;
      }

      if (lastMonthGmv <= 0 && currentGmv > 0) {
        return 100;
      }

      if (lastMonthGmv > 0 && currentGmv <= 0) {
        return -100;
      }

      if (lastMonthGmv > currentGmv) {
        let compare = lastMonthGmv - currentGmv;
        let result = Math.round(compare / currentGmv * 100)
        return -result;
      }

      let compare = currentGmv - lastMonthGmv;
      return  Math.round(compare / lastMonthGmv * 100)
    }
  },
  watch: {
    $route: "getList",
    currentPage() {
      this.getList();
    },
  },
  created() {
    this.getList();
    this.getCodes();
  }
};
</script>

<style >
.mx-datepicker{
  width: 100%;
}
.filter-transaction{
  padding-top: 30px;
}
</style>
